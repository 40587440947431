<template>
  <div class="detail">
    <el-dialog :visible.sync="dialogVisible" width="30%" center @close="dialogVisible = false">
      <div>
        <div class="detail-item">
          <span class="detail-item-desc">发送对象：</span>
          <span>{{
            `共${formInline.mobileCount}条，其中${formInline.notMemberMobileCount}条非会员`
          }}</span>
          <span class="detail-item-tip">*短信内容如果含参数，非会员将接收不到。</span>
          <p class="detail-item-mobiles">{{ formInline.mobiles }}</p>
        </div>
        <div class="detail-item">
          <span class="detail-item-desc">模板：</span>
          <span>{{ formInline.templateName }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-item-desc"> 内容：</span>
          <p class="detail-item-content">
            {{ formInline.content }}
          </p>
        </div>
        <div class="detail-item">
          <span class="detail-item-desc">任务类型：</span>
          <span>{{ formInline.taskTypeDesc }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-item-desc">发送时间：</span>
          <span>{{ formInline.sendTime }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-item-desc">发送状态：</span>
          <span>{{ formInline.taskStatusDesc }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 认</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      formInline: {},
    };
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.getDetail(id);
    },
    getDetail(id) {
      const opt = {
        url: "/acb/2.0/memberSmsTask/queryTaskDetail",
        method: "get",
        data: {
          taskId: id,
        },
        success: (res) => {
          if (res.state == 0) {
            this.formInline = res.value;
          }
        },
        fail: (err) => {},
      };
      this.$request(opt);
    },
  },
};
</script>

<style lang="stylus" scoped>
.detail
  .detail-item
    margin 50px auto
    .detail-item-tip
      margin-left 40px
      color #999
    .detail-item-content
      text-indent 3em
      line-height 30px
    .detail-item-mobiles
      margin 20px 0 0 73px
      line-height 30px
</style>
