<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper bgFFF">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="100px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Operator')">
            <el-autocomplete
              class="inline-input"
              v-model="bossManagerName"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="bossManagerName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('searchModule.state')">
            <el-select v-model="formInline.taskStatus">
              <el-option label="全部" value=""></el-option>
              <el-option label="已发送" :value="1"></el-option>
              <el-option label="待发送" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Sending_time')">
            <el-date-picker
              v-model="date"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <AuthorityComponent
            ComponentName="el-button"
            :permission="['button.query']"
            type="primary"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >搜索
          </AuthorityComponent>
          <el-button @click="resetForm('formInline')">条件{{ $t('button.reset') }}</el-button>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table :data="tableData" stripe style="width: 100%" v-loading="loading">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            v-for="item in tabCols"
            align="center"
            :label="item.label"
            :prop="item.prop"
            :key="item.prop"
            :width="item.width"
            :formatter="item.formatter"
          >
          </el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary" size="small"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                    >详情</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="scope.row.taskStatus == 0 && $route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <detail ref="detail"></detail>
    </div>
  </div>
</template>
<script>
import detail from "./components/shortMessageDetail";
import { dateFormat } from "../../../common/js/public.js";
export default {
  data() {
    return {
      formInline: {
        taskStatus: "",
        operatorId: "",
      },
      bossManagerName: "",
      date: [],
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      loading: false,
      tabCols: [
        { label: "短信模板", prop: "templateName", width: "200" },
        { label: "内容", prop: "content" },
        { label: this.$t("list.Operator"), prop: "operatorName", width: "150" },
        { label: this.$t("list.Sending_time"), prop: "sendTime", width: "300" },
        { label: this.$t("list.state"), prop: "taskStatusDesc", width: "150" },
      ],
    };
  },
  components: {
    detail,
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 1,
            size: 20,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.operatorId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.operatorId = item.bossManagerId;
    },
    searchData() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/memberSmsTask/queryTaskPage",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
          sendTimeStart: dateFormat(this.date[0], "yyyy-MM-dd HH:mm:ss") || "",
          sendTimeEnd: dateFormat(this.date[1], "yyyy-MM-dd HH:mm:ss") || "",
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = parseInt(res.value.total) || 0;
          }
          this.loading = false;
        },
        fail: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.searchData();
    },
    handleCommand(command, row) {
      if (command == "a") {
        this.$refs.detail.open(row.id);
      } else {
        this.$confirm("任务尚未执行，确认删除吗？", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: "/acb/2.0/memberSmsTask/delete",
              method: "get",
              data: {
                taskId: row.id,
              },
              success: (res) => {
                if (res.state == 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.searchData();
                }
              },
              fail: (err) => {},
            };
            this.$request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
  created() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped rel="stylesheet/stylus">
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
</style>
